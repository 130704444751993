import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PortableText from "../components/portableText"
import { Link, FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"
import makeCarousel from "react-reveal/makeCarousel"
import localize from "../components/localize"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { SRLWrapper } from "simple-react-lightbox"
import SEO from "../components/seo"

const CarouselUI = ({ children }) => <Container>{children}</Container>
const Carousel = makeCarousel(CarouselUI)

export const query = graphql`
  query ($slug: String) {
    sanityCourses(slug: { current: { eq: $slug } }) {
      title {
        _type
        es
        pt
        en
      }
      image {
        asset {
          url
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawExcerpt(resolveReferences: { maxDepth: 10 })
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`

const CoursesTemplatePage = ({ data }) => (
  <Layout>
    <SEO
      title={data.sanityCourses.title}
      image={data.sanityCourses.image.asset.url}
    />

    <header
      className="relative px-6 overflow-hidden bg-gray-900 "
      style={{ minHeight: "80vh" }}
    >
      <div
        className="flex items-center justify-center pt-32"
        style={{ minHeight: "80vh" }}
      >
        <div className="relative z-20 text-center">
          
          <h1 className="font-serif text-3xl text-white md:text-5xl">
            {data.sanityCourses.title}
          </h1>
          <div className="max-w-3xl px-6 py-6 mx-auto">
            <div className="font-sans text-base leading-loose text-center text-gray-100 md:text-xl">
              {data.sanityCourses._rawExcerpt && (
                <PortableText blocks={data.sanityCourses._rawExcerpt} />
              )}
            </div>
          </div>
          <Link
            to="/cursos"
            className="inline-block mt-6 font-serif text-xl text-white border-b-2 border-gray-700"
          >
            <FormattedMessage id="back_course" />
          </Link>
        </div>
      </div>
      <div className="absolute inset-0 z-10 opacity-20">
        <Img
          fluid={data.sanityCourses.image.asset.fluid}
          className="object-cover w-full h-screen "
        />
      </div>
    </header>
    <SRLWrapper>
      <div className="flex items-center justify-start max-w-2xl mx-auto">
        <Link
          to={`/contact/`}
          className="inline-block px-6 py-2 mt-6 font-serif text-xl font-bold text-center text-gray-900 no-underline uppercase transition-all duration-500 transform rounded-md "
        >
          <FormattedMessage id="free_trial" />
          <span className="opacity-75">(40 min.)</span>
        </Link>
      </div>

      <div className="hidden w-full p-3 py-12 mx-auto font-serif text-2xl text-center text-gray-100 bg-indigo-800 ">
        <p>
          <FormattedMessage id="flex_price" />
        </p>
      </div>
      <article className="max-w-2xl py-12 pt-6 mx-auto">
        <div className="max-w-2xl px-6 mx-auto prose text-white md:prose-xl">
          {data.sanityCourses._rawBody && (
            <PortableText blocks={data.sanityCourses._rawBody} />
          )}
        </div>
      </article>
    </SRLWrapper>

    <section className="relative flex flex-col mx-auto ">
      <div
        className="relative flex items-center justify-center py-24 pt-24 overflow-hidden bg-gray-900 "
        style={{ minHeight: "60vh" }}
      >
        <div className="absolute inset-0 opacity-20">
          <Carousel
            defaultWait={5000}
            maxTurns={100} /*wait for 1000 milliseconds*/
          >
            <Fade>
              <img
                className="object-cover h-screen"
                alt="ajmatova"
                src="https://cdn.sanity.io/images/723nv3uz/production/d2ca19d993f2d5d8fe2d3b546a41085d5d7b9cf7-5948x3965.jpg?w=2000&h=903&fit=crop&fm=webp"
              />
            </Fade>
            <Fade>
              <img
                className="object-cover h-screen"
                alt="ajmatova"
                src="https://cdn.sanity.io/images/723nv3uz/production/b83b1fba106076daca45bad34f0d9d1e5fd97acf-7360x4912.jpg?w=2000&h=934&fit=crop&fm=webp"
              />
            </Fade>
            <Fade>
              <img
                className="object-cover h-screen"
                alt="ajmatova"
                src="https://cdn.sanity.io/images/723nv3uz/production/27a46a5ab8670fdd151a32d4a8616e9dbf2a0d78-2000x1279.jpg?w=2000&h=1000&fit=crop&fm=webp"
              />
            </Fade>
          </Carousel>
        </div>
        <div className="relative z-40 flex flex-col items-center justify-center max-w-2xl mx-auto text-center">
          <div className="px-12 lg:px-0 ">
            <h1
              className="font-serif text-xl text-gray-100 md:text-3xl"
              style={{ minHeight: "2.25rem" }}
            >
              {data.sanityCourses.title}
            </h1>
          </div>
          <p className="px-6 py-6 font-sans text-lg text-gray-100 md:text-xl ">
            <FormattedMessage id="contact_us_more_info" />
          </p>
          <Link
            to={`/contact`}
            className="px-8 py-2 font-serif text-lg font-bold tracking-wide text-gray-800 uppercase duration-500 bg-gray-100 shadow-md hover:bg-gray-800 hover:text-white"
          >
            <FormattedMessage id="contact_us" />
          </Link>
        </div>
      </div>
    </section>
  </Layout>
)

export default localize(CoursesTemplatePage)

const Container = styled.div`
  ${tw`relative z-10 flex items-center justify-center w-full mx-auto overflow-hidden text-base bg-gray-100 md:block `}
  height: 100vh;

  .gatsby-image-wrapper {
    ${tw`h-screen `}
  }

  img {
    ${tw`object-cover w-full h-screen md:h-auto`}
  }
`
